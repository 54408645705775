import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Input, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Spinner } from 'reactstrap';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../../assets/css/ckeditor.css';
import uuid from 'react-uuid';

export const ExamReadyEvents = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [originalData, setOriginalData] = useState({ documents: [] }); // State to hold the original data
    const [filteredData, setFilteredData] = useState({ documents: [] }); // State to hold the filtered data
    const [modal, setModal] = useState(false); // State to manage the modal visibility
    const [update, setUpdate] = useState(false);
    const [formData, setFormData] = useState({
        id: uuid(),
        title: '',
        registerLink: '',
        description: '',
        price: '',
        date: '',
    });
    const [isEditing, setIsEditing] = useState(false); // State to track if we are editing an existing Boss Call

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        const filtered = originalData.documents.filter(item =>
            item.bosscallname.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredData({ documents: filtered });
    };

    useEffect(() => {
        axios.get('https://exam-ready-api-1b6013bf419a.herokuapp.com/events/')
            .then(response => {
                // handle success
                const documents = response.data;
                console.log(documents);
                setFilteredData({ documents });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            }).finally(() => {
                setLoading(false);
            });
    }, [update]);

    const toggleModal = () => {
        setModal(!modal);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCKEditorChange = (event, editor) => {
        const data = editor.getData();
        setFormData({
            ...formData,
            description: data
        });
    };

    const handleSubmit = async (e) => {
        setUploading(true);
        e.preventDefault();
        try {
            let response;
            if (isEditing) {
                response = await axios.post('https://exam-ready-api-1b6013bf419a.herokuapp.com/events/update', formData);
            } else {
                console.log('formData', formData);  
                response = await axios.post('https://exam-ready-api-1b6013bf419a.herokuapp.com/events/add', formData);
            }

            const result = response.data;
            console.log(result);
            if (result.success) {
                alert(`Event ${isEditing ? 'updated' : 'added'} successfully`);
                setModal(false);
                setUpdate(!update);
                setUploading(false);
            } else {
                alert(`Failed to ${isEditing ? 'update' : 'add'} Event`);
            }
        } catch (error) {
            console.error(`Error ${isEditing ? 'updating' : 'adding'} Event:`, error);
            alert(`An error occurred while ${isEditing ? 'updating' : 'adding'} the Event`);
            setUploading(false);
        }
    };

    const handleEdit = (item) => {
        setFormData(isEditing?{
            id:item.id,
            title: item.title,
            registerLink: item.registerLink,
            description: item.description,
            price: item.price,
            date: item.date,
        }: {
            id:item.id,
            title: item.title,
            registerLink: item.registerLink,
            description: item.description,
            price: item.price,
            date: item.date,
        });
        setIsEditing(true);
        setModal(true);
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this Event?')) {
            return;
        }
//https://hdhr-apps-api.herokuapp.com
        try {
            const response = await axios.post('https://exam-ready-api-1b6013bf419a.herokuapp.com/events/delete', {id});
            if (response.data.success) {
                alert('Event deleted successfully');

                setUpdate(!update);
            } else {
                alert('Failed to delete Event');
            }
        } catch (error) {
            console.error('Error deleting Event:', error);
            alert('An error occurred while deleting the Event');
        }
    };

    if (loading) {
        return (<Spinner />);
    } else {
        return (
            <React.Fragment>
                <Container>
                    <Row className="mt-2 mb-2">
                        <Col>
                            <Button color="primary" style={{ width: '100%' }} onClick={() => {
                                setIsEditing(false);
                                setFormData({
                                    id: uuid(),
                                    title: '',
                                    registerLink: '',
                                    description: '',
                                    price: '',
                                    date: '',
                                });
                                toggleModal();
                            }}>+ New</Button>
                        </Col>
                        <Col></Col>
                        <Col>
                            <Input
                                placeholder='Search'
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th>Event Name</th>
                                        <th>Register Link</th>
                                        <th>Price</th>
                                        <th>Date</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.documents.sort((a, b) => new Date(b.dateOfCall) - new Date(a.dateOfCall)).map((item, index) => (
                                            <tr key={index}>
                                            <td>{item.title}</td>
                                            <td><a href={item.registerLink} target='_blank' rel='noopener noreferrer'>Register</a></td>
                                            <td>{item.price}</td>
                                            <td>{item.date}</td>
                                            <td>
                                                <Button color="warning" onClick={() => handleEdit(item)}>Edit</Button>{' '}
                                                <Button color="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Modal size='lg' isOpen={modal} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>{isEditing ? 'Edit Event' : 'Add Event'}</ModalHeader>
                        <ModalBody>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="bosscallname">Event Title</Label>
                                    <Input
                                        type="text"
                                        name="title"
                                        id="title"
                                        value={formData.title}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="registerLink">Register Link</Label>
                                    <Input
                                        type="text"
                                        name="registerLink"
                                        id="registerLink"
                                        value={formData.registerLink}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                      
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={formData.description}
                                        onChange={handleCKEditorChange}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="credits">Price</Label>
                                    <Input
                                        type="text"
                                        name="price"
                                        id="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="dateOfCall">Date</Label>
                                    <Input
                                        type="date"
                                        name="date"
                                        id="date"
                                        value={formData.date}
                                        onChange={handleChange}
                                        required
                                    />
                                </FormGroup>
                                
                                <ModalFooter>
                                    <Button color="primary" type="submit">{isEditing ? 'Update' : 'Submit'} <Spinner size="sm" style={{ display: uploading ? 'inline-block' : 'none' }} /></Button>
                                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                                </ModalFooter>
                            </Form>
                        </ModalBody>
                    </Modal>
                </Container>
            </React.Fragment>
        );
    }
};

export default ExamReadyEvents;
